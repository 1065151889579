
.phoenix-career-service {
    position: relative;
    overflow: hidden;
    color: var(--text-default);
    background: var(--background-default)
}

.phoenix-career-service .content-container {
    max-width: 90rem;
    margin: 0 auto;
    padding: 6.25rem calc(min(7.5rem, 8.34%)) 0;
    position: relative;
    z-index: 2
}

.phoenix-career-service .dot-grid-container {
    height: 170%;
    position: absolute;
    left: 61em;
    top: -35%;
    bottom: -35%;
    display: flex;
    align-items: center;
    z-index: -1
}

.phoenix-career-service .dot-grid-container img {
    height: 100%
}
.phoenix-career-service .top-cack {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.5rem
}

.phoenix-career-service .top-cack .heading-container {
    width: 46.65%;
    margin-bottom: 2.5rem;
    text-align: center
}

.phoenix-career-service .top-cack .heading-container .heading {
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 130%;
    margin-bottom: 1.5rem
}

.phoenix-career-service .top-cack .heading-container .description {
    font-size: 1.125rem;
    line-height: 150%
}

.phoenix-career-service .top-cack .salaries-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.phoenix-career-service .top-cack .salaries-container .cacks {
    display: flex;
    align-items: center;
    border: 2px solid var(--border-default);
    border-radius: 1rem;
    padding: 2rem 4.5rem
}

.phoenix-career-service .top-cack .salaries-container .cacks .cack {
    white-space: nowrap;
    flex: 1;
    display: flex;
    gap: 1rem
}

.phoenix-career-service .top-cack .salaries-container .cacks .cack .icon {
    width: 4rem;
    height: auto;
    flex-shrink: 0
}

.phoenix-career-service .top-cack .salaries-container .cacks .cack .value-label-container {
    display: flex;
    flex-direction: column;
    gap: .25rem
}

.phoenix-career-service .top-cack .salaries-container .cacks .cack .value-label-container .value {
    font-weight: 800;
    font-size: 2rem;
    line-height: 130%;
    color: var(--text-default)
}

.phoenix-career-service .top-cack .salaries-container .cacks .cack .value-label-container .label {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 130%;
    color: var(--text-disabled)
}

.phoenix-career-service .top-cack .salaries-container .cacks .separator {
    margin: auto 3.75rem;
    width: 1px;
    height: 2.5rem;
    border-left: 2px solid var(--border-default)
}

.phoenix-career-service .bottom-cack {
    margin-bottom: 4.5rem
}

.phoenix-career-service .bottom-cack .cacks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem 4rem
}

.phoenix-career-service .bottom-cack .cacks .cack {
    width: 15rem
}

.phoenix-career-service .bottom-cack .cacks .cack .cack-title-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin-bottom: .5rem
}

.phoenix-career-service .bottom-cack .cacks .cack .cack-title-container .icon {
    height: 4rem;
    width: 4rem
}

.phoenix-career-service .bottom-cack .cacks .cack .cack-title-container .heading {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 140%
}

.phoenix-career-service .bottom-cack .cacks .cack .sub-heading {
    font-size: 1rem;
    line-height: 150%
}

.phoenix-career-service .cta-section {
    background: linear-gradient(145.07deg,#683fbe -2.83%,#493080 89%);
    box-shadow: 0 .625rem 1.25rem .625rem rgba(104,63,190,.1);
    border-radius: .75rem;
    padding: 1.5rem 3.5rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--white);
    z-index: 2;
    overflow: hidden
}

.phoenix-career-service .cta-section .left-section {
    display: flex;
    align-items: center
}

.phoenix-career-service .cta-section .image {
    height: 4.5rem;
    width: 4.5rem;
    margin-right: 1.25rem
}

.phoenix-career-service .cta-section .heading {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 140%;
    margin-bottom: .75rem
}

.phoenix-career-service .cta-section .sub-heading {
    font-size: 1rem;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: var(--surface-hover)
}

.phoenix-career-service .cta-section .sub-heading img {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: .375rem;
    filter: brightness(0) saturate(100%) invert(100%) sepia(3%) saturate(1410%) hue-rotate(223deg) brightness(119%) contrast(87%)
}

.phoenix-career-service .cta-section .cta-container {
    width: -webkit-max-content!important;
    width: -moz-max-content!important;
    width: max-content!important
}

.phoenix-career-service .cta-section .cta-container .apply-btn {
    padding: 1rem 3rem;
    background: #e8ddff;
    color: #171421;
    font-weight: 700;
    font-size: 1rem;
    line-height: 125%
}

.phoenix-career-service .cta-section .cta-container .apply-btn img {
    filter: brightness(0) saturate(100%) invert(6%) sepia(21%) saturate(1412%) hue-rotate(213deg) brightness(92%) contrast(94%)
}

.phoenix-career-service .cta-section .lines {
    z-index: -1;
    position: absolute;
    width: 23rem;
    transform: rotate(149.58deg);
    right: -5rem;
    bottom: -17rem
}

@media only screen and (max-width: 1000px) {
    .phoenix-career-service .top-cack {
        flex-direction:column
    }

    .phoenix-career-service .top-cack .heading-container {
        width: 100%;
        margin-bottom: 2.5rem
    }

    .phoenix-career-service .top-cack .salaries-container {
        width: 100%
    }

    .phoenix-career-service .bottom-cack {
        background: none;
        border: none;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        margin-bottom: 1.5rem
    }

    .phoenix-career-service .bottom-cack .cacks,.phoenix-career-service .bottom-cack .cacks .cack {
        margin: 0
    }

    .phoenix-career-service .cta-section {
        flex-direction: column
    }

    .phoenix-career-service .cta-section .left-section {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 1.5rem
    }

    .phoenix-career-service .cta-section .image {
        margin-right: 0;
        margin-bottom: .75rem
    }

    .phoenix-career-service .cta-section .heading {
        margin-bottom: 1rem
    }

    .phoenix-career-service .cta-section .sub-heading {
        align-items: flex-start
    }
}

@media only screen and (max-width: 768px) {
    .phoenix-career-service .top-cack .salaries-container .cacks {
        border:0;
        border-radius: 0;
        padding: 0
    }

    .phoenix-career-service .top-cack .salaries-container .cacks .cack {
        flex-direction: column;
        align-items: center
    }

    .phoenix-career-service .top-cack .salaries-container .cacks .cack .value-label-container {
        align-items: center
    }
}

@media only screen and (max-width: 512px) {
    .phoenix-career-service .content-container {
        padding:3.5rem calc(min(1.25rem, 5.55%)) 0
    }

    .phoenix-career-service .top-cack {
        margin-bottom: 1rem
    }

    .phoenix-career-service .top-cack .heading-container .heading {
        font-size: 1.5rem;
        margin-bottom: .75rem
    }

    .phoenix-career-service .top-cack .heading-container .description {
        font-size: .875rem
    }

    .phoenix-career-service .top-cack .salaries-container .cacks .cack .icon {
        width: 3rem;
        height: auto
    }

    .phoenix-career-service .top-cack .salaries-container .cacks .cack .value-label-container .value {
        font-weight: 700;
        font-size: 1.5rem
    }

    .phoenix-career-service .top-cack .salaries-container .cacks .cack .value-label-container .label {
        font-size: .875rem
    }

    .phoenix-career-service .top-cack .salaries-container .cacks .separator {
        margin: auto 2.5rem
    }

    .phoenix-career-service .bottom-cack .cacks {
        gap: 0 1rem
    }

    .phoenix-career-service .bottom-cack .cacks .cack {
        width: 100%;
        padding: 1rem 0
    }

    .phoenix-career-service .bottom-cack .cacks .cack .cack-title-container {
        flex-direction: row;
        gap: .75rem;
        align-items: center
    }

    .phoenix-career-service .bottom-cack .cacks .cack .cack-title-container .icon {
        height: 3rem;
        width: 3rem
    }

    .phoenix-career-service .bottom-cack .cacks .cack .cack-title-container .heading {
        font-size: 1.125rem
    }

    .phoenix-career-service .bottom-cack .cacks .cack .sub-heading {
        font-size: .875rem
    }

    .phoenix-career-service .cta-section {
        padding: 1.5rem 1.25rem
    }

    .phoenix-career-service .cta-section .image {
        height: 3.5rem;
        width: 3.5rem;
        margin-bottom: .75rem
    }

    .phoenix-career-service .cta-section .heading {
        font-size: 1rem;
        margin-bottom: 1rem
    }

    .phoenix-career-service .cta-section .sub-heading {
        font-size: .875rem
    }

    .phoenix-career-service .cta-section .sub-heading img {
        height: 1.25rem;
        width: 1.25rem
    }

    .phoenix-career-service .cta-section .cta-container {
        width: 100%!important
    }

    .phoenix-career-service .cta-section .cta-container .apply-button-container {
        width: 100%
    }

    .phoenix-career-service .cta-section .cta-container .apply-btn {
        width: 100%;
        padding: .875rem;
        font-size: .75rem;
        justify-content: center
    }

    .phoenix-career-service .cta-section .lines {
        bottom: -15rem
    }
}
.section-4 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
}
.section4-row {
    overflow: hidden;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.section4-content h2 {
    font-size: 30px;
    font-family: 'upSkill-Bold';
}
.section4-content p {
    font-size: 20px;
    font-family: 'upSkill-light';
}
.y {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

