/* Popup style */
.popup-box {
  position: fixed;
  background: #3d3c3c50;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}
.popup-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-btn {
  margin-top: 20px;
  margin-bottom: 20px;
}
.box {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #920994;
  color: white;
  cursor: pointer;
  border-radius: 0px 3px 0px 0px;
}
.profrom {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.proform-input {
  width: 90%;
  padding: 10px;
  outline: none;
}
.prosubmit {
  font-size: 20px;
  border: double black;
  padding: 5px 20px 5px 20px;
}
.prosubmit:hover {
  border: double orangered;
}
.pop-form {
  background-color: rgb(146, 9, 148);
}
.form-button1 {
  background-color: #b409b7;
}
.form-button1:hover {
  background-color: #920994;
}
@media (max-width: 767px) {
  .close-icon {
    border-radius: 0 9px 0 0;
  }
  .box {
    border-radius: 10px;
    width: 90%;
  }
}
