.english-banner , .jobopen{
    width: 100%;
    height: 100vh;
}
.english-banner img , .jobopen img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
/*** Carousel Hero Header Start ***/
.header-carousel .header-carousel-item {
    height: 700px;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background: var(--bs-light);
    color: var(--bs-primary);
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.header-carousel .owl-nav .owl-prev {
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 30px;
}
.header-carousel .owl-nav .owl-next {
    bottom: 30px;
    right: 50%;
    transform: translateX(-50%);
    margin-right: 30px;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
    box-shadow: inset 0 0 100px 0 var(--bs-secondary);
    color: var(--bs-white);
}

.header-carousel .header-carousel-item .carousel-caption {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .2);
    display: flex;
    align-items: center;
}

@media (max-width: 991px) {
    .header-carousel .header-carousel-item .carousel-caption {
        padding-top: 45px;
    }

    .header-carousel .header-carousel-item {
        height: 1300px;
    }
}

@media (max-width: 767px) {
    .header-carousel .header-carousel-item {
        height: 950px;
    }

    .header-carousel .owl-nav .owl-prev {
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: -45px;
    }

    .header-carousel .owl-nav .owl-next {
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 45px;
    }
}
/*** Carousel Hero Header End ***/