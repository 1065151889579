/* Navbar start */
#submenu-dropdown {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate3d(210px, 76px, 0px);
}
#dropdown-button {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate3d(622px, 52px, 0px);
}
/* Navbar end  */
.textgrad {
  background: -webkit-radial-gradient(
    circle,
    rgb(0, 0, 0) 0%,
    rgb(62, 12, 113) 50%,
    rgb(194, 23, 197) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.5 !important;
}
.liner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: rgb(12, 12, 12);
  background: linear-gradient(
    45deg,
    rgb(0, 0, 0) 0%,
    rgb(62, 12, 113) 50%,
    rgb(194, 23, 197) 100%
  );
}
.section-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 2rem 0rem;
}
.section-container-elipse1 {
  width: 12%;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  position: absolute;
  left: 0;
}
.section-container-elipse2 {
  width: 12%;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  position: absolute;
  left: 0;
}
.section-container-start {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.section-container-start-1 {
  width: 24%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  background-color: #f8eff8;
}
.section-container-start-1-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.section-container-start-1-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-container-start-1-image > img {
  width: auto;
  height: auto;
}
.section-container-start-1-desc {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-container-start-1-desc > span {
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #670e8c;
}
.section-container-start-1-ellipse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* courser */
.swiper {
  width: 80%;
  margin-bottom: 1rem;
  transition: 3s ease-in-out;
}
.swiperh3 {
  text-align: center;
  padding-left: 3%;
  padding-right: 3%;
}
.swiper-slide {
  text-align: center;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.swiper-slide h2 {
  padding-top: 3%;
}
.swiper-slide button {
  color: #fff;
  background: rgb(83, 4, 115);
  background: linear-gradient(
    0deg,
    rgba(83, 4, 115, 1) 42%,
    rgba(156, 7, 217, 1) 100%
  );
  padding: 15px;
  border-radius: 8px;
}

/* tabs  */
.tabcon {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 2vh;
}
.tabss {
  border: #670e8c 1px solid;
  padding-top: 20px;
  padding-bottom: 20px;
}
.tab-btn {
  border: solid 1px rgb(255, 0, 0);
  cursor: pointer;
  flex-grow: 1;
  flex-shrink: 1;
}
/* cards  */
.cards {
  padding: 20px;
}
/* students  */
.students {
  width: 100%;
  padding: 5vh 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.totalstudents {
  width: 45%;
  height: 100%;
  text-align: center;
  background: rgb(83, 4, 115);
  background: linear-gradient(
    0deg,
    rgba(83, 4, 115, 1) 42%,
    rgba(156, 7, 217, 1) 100%
  );
  padding-bottom: 10vh;
}
.student2 {
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
}
.placed {
  width: 100%;
  background: rgb(83, 4, 115);
  background: linear-gradient(
    0deg,
    rgba(83, 4, 115, 1) 42%,
    rgba(156, 7, 217, 1) 100%
  );
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 5%;
  padding-right: 5%;
}
.placed:nth-child(1) {
  display: flex;
}
.placed:nth-child(2) {
  text-align: center;
  margin: 0;
  line-height: 75px;
}
/* reviews */
.test {
  width: 100%;
  margin-bottom: 5vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: #d9d9d9;
  padding: 5%;
}
.test h2 {
  width: 100%;
  margin-bottom: 2vh;
}
.review {
  width: 45%;
  padding: 5%;
  background-color: #fff;
  gap: 20px;
}
/* footer  */
.footer {
  background: linear-gradient(
    45deg,
    rgb(0, 0, 0) 0%,
    rgb(62, 12, 113) 50%,
    rgb(146, 9, 148) 100%
  );
}
/* contect css  */
.container-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.subcontainer-1 {
  width: 100%;
  display: flex;
}
.subcontainer-1-head {
  height: 125px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #39035b;
}
.subcontainer-1-head > span {
  font-size: 42px;
  font-weight: 800;
  line-height: 63px;
}
.subcontainer-1-main {
  margin: 2rem 0rem;
  width: 80%;
  height: 650px;
  display: flex;
  border: 1px solid #b4b0b0;
  border-radius: 34px;
}
.subcontainer-1-start {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subcontainer-1-start-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.subcontainer-1-start-details-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 386px;
  height: 68px;
  border-radius: 5px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #b4b0b0;
}
.subcontainer-1-start-details-1 > span {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
}
.subcontainer-1-start-details-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 386px;
  height: 68px;
  border-radius: 5px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #b4b0b0;
  background-color: #39035b;
}
.subcontainer-1-start-details-2 > span {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
}
.subcontainer-1-end {
  width: 50%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e0dada;
}
.subcontainer-1-end-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subcontainer-1-end-details-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8%;
}
.subcontainer-1-end-details-1 > p {
  font-size: 25px;
  font-weight: 500;
  line-height: 45.99px;
}
.subcontainer-1-end-details-1 > p > span {
  font-size: 28px;
  font-weight: 900;
  line-height: 45.99px;
}
.subcontainer-1-end-details-2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subcontainer-1-end-details-2-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.subcontainer-1-end-details-2-form-name {
  width: 47%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#name,
#mail,
#number,
#message {
  width: 100%;
  padding: 20px;
  background-color: #f5f2f2;
  border: 1px #b4b0b0 solid;
  border-radius: 8px;
}
.subcontainer-1-end-details-2-form-email {
  width: 47%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subcontainer-1-end-details-2-form-number {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subcontainer-1-end-details-2-form-message {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subcontainer-1-end-details-2-form-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
}
.form-button {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.button-submit {
  background-color: #39035b;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  padding: 1rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all 0.5s;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  width: 100%;
}
.button-submit:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: 0.1s;
}
.group {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.contentcourse {
  background-color: #d0cdcd;
  list-style: none;
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  text-align: center;
}
.contentcourse::after {
  position: absolute;
  content: "";
  width: 1%;
  left: 0;
  height: 100%;
  background-color: #39035b;
  transition: 0.5s linear;
}
.contentcourse p {
  width: 100%;
  color: #39035b;
  text-align: center;
  transition: 0.5s linear;
  z-index: 999;
}
.contentcourse:hover:after {
  width: 100%;
  color: white;
}
.contentcourse:hover p {
  color: #ffffff;
}
.full {
  text-align: center;
  font-size: 20px;
}
/* button  */
.course-button {
  cursor: pointer;
  font-weight: 700;
  transition: all 0.2s;
  padding: 10px 20px;
  border-radius: 5px;
  background: #39035b;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 15px;
}
.course-button:hover {
  background: #660d9d;
}
.course-button > span {
  color: #fff;
}
.course-button > svg {
  width: 34px;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}
.course-button:hover svg {
  transform: translateX(5px);
}
.course-button:active {
  transform: scale(0.95);
}
.wrapper {
  display: inline-flex;
  list-style: none;
  height: 120px;
  width: 100%;
  padding-top: 40px;
  font-family: "Poppins", sans-serif;
  justify-content: center;
}
.wrapper .icon {
  position: relative;
  background: #fff;
  border-radius: 50%;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #fff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  bottom: -3px;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}
.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #fff;
}
.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background: #1da1f2;
  color: #fff;
}
.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #e4405f;
  color: #fff;
}
.top_of_line {
  position: fixed;
  z-index: 999;
  right: 10px;
  bottom: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
}
.app {
  cursor: pointer;
  padding: 10px;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
.show {
  display: flex;
  transition: all 2s ease-in-out;
}
.hide {
  display: none;
}
.show:hover .hide {
  display: flex;
}

/* animation */

.ani {
  animation: upword linear;
  animation-timeline: view();
  animation-delay: entry 10 cover 10%;
}
@keyframes upword {
  from {
    scale: 0.9;
  }
  to {
    scale: 1;
  }
}
.drop {
  z-index: 99999;
}
.swiper-container {
  height: calc(50vh - 120px);
  margin: 60px;
}
.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear;
}
.swiper-slide {
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}
.copy {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  letter-spacing: 0.06em;
}
/* inner banner courses  */
#post-header {
  border-radius: 12px;
  background: linear-gradient(
    45deg,
    rgb(0, 0, 0) 0%,
    rgb(62, 12, 113) 50%,
    rgb(146, 9, 148) 100%
  );
  color: #fff;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
}
.content-max-width {
  margin: 0 auto;
  max-width: 1280px;
}
#post-header .icon-post-header-dots-sm,
#post-header .icon-post-header-dots-lg,
#post-header .icon-post-header-stars {
  position: absolute;
  z-index: 0;
}
#post-header .icon-post-header-dots-sm {
  top: 30px;
  left: 30px;
}
#post-header .icon-post-header-dots-lg {
  bottom: -20%;
  right: -20%;
}
#post-header .icon-post-header-stars {
  bottom: 30px;
  left: 30px;
}
#post-title {
  font-size: 3rem;
}
#hero {
  width: 100%;
  background: linear-gradient(
    45deg,
    rgb(0, 0, 0) 0%,
    #3e0c71 50%,
    rgb(146, 9, 148) 100%
  );
}
.img-fluid {
  width: 70%;
  height: 70%;
}
#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.button {
  text-decoration: none;
  line-height: 1;
  border-radius: 1.5rem;
  overflow: hidden;
  position: relative;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  color: #121212;
  border: none;
  cursor: pointer;
  --clr: #00ad54;
}

.button-decor {
  position: absolute;
  inset: 0;
  background-color: var(--clr);
  transform: translateX(-100%);
  transition: transform 0.3s;
  z-index: 0;
}

.button-content {
  display: flex;
  align-items: center;
  font-weight: 600;
  position: relative;
  overflow: hidden;
}

.button__icon {
  width: 48px;
  height: 40px;
  background-color: var(--clr);
  display: grid;
  place-items: center;
}

.button__text {
  display: inline-block;
  transition: color 0.2s;
  padding: 2px 1.5rem 2px;
  padding-left: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.button:hover .button__text {
  color: #fff;
}

.button:hover .button-decor {
  transform: translate(0);
}
.call-button {
  font-family: inherit;
  font-size: 18px;
  color: white;
  padding: 0.4rem 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 25px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.call-button:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.call-button span {
  display: block;
  margin-left: 0.4em;
  transition: all 0.3s;
}
.call-button svg {
  width: 18px;
  height: 18px;
  fill: white;
  transition: all 0.3s;
}
.call-button .svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 0.5em;
  transition: all 0.3s;
}
.call-button:hover .svg-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
}
.call-button:hover svg {
  transform: rotate(45deg);
}
/* slider  */
.slider {
  text-align: center;
}
.linerr{
  position: relative;
  display: flex;
  padding: 0 50px;
}
.linerr:before , .linerr:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}
.linerr:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}
.linerr::after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}
.slider-title {
  margin-bottom: 50px;
}
.image_wrapper img {
  width: 150px;
  margin-left: 10px;
  margin-right: 10px;
  object-fit: contain;
  aspect-ratio: 3/2;
}





.App {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  background-color: #f4f4f4;
}

button {
  padding: 10px 20px;
  background-color: #3897f0;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

button:hover {
  background-color: #3578d4;
}

pre {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
}

h2 {
  color: #333;
}







