/* mobile  */
@media (min-width: 320px) and (max-width: 480px) {
  .swiper {
    width: 98%;
  }
  .swiperh3 {
    font-size: 15px;
  }
  .swiper-slide h2 {
    font-size: 20px;
  }
  .swiper-slide p {
    padding: 0;
  }
  .swiper-slide button {
    padding: 10px;
    font-size: 12px;
  }
  .section-container-start {
    width: 90%;
    gap: 20px;
  }
  .section-container-start-1-1 {
    gap: 10px;
    padding: 15px;
  }
  .section-container-start {
    justify-content: center;
  }
  .section-container-start-1 {
    padding: 10px;
    width: 45%;
  }
  .section-container-start-1-image svg {
    width: 40px;
  }
  .section-container-start-1-desc > span {
    font-size: 15px;
  }
  .test h2 {
    font-size: 18px;
  }
  .section-container-start {
    width: 98%;
    gap: 5px;
  }
  .section-container-start-1 {
    width: 45%;
  }
  .tools-covered-container .content-container{
    padding: 0px;
  }
  .tools-covered{
    width: 100%;
    padding: 0;
    padding-top: 1rem;
  }
  .tools-covered-container .tools-covered .heading{
    font-size: 1.5rem;
    width: 90%;
  }
  .tools-covered-container .tools-covered .description{
    font-size: 14px;
    margin-bottom: 1.5rem;
    width: 100%;
  }
  .contentcourse{
    text-align: center;
  }
  .contentcourse p{
    font-size: 14px;
    padding: 5px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .section-container-start {
    width: 90%;
    gap: 20px;
  }
  .section-container-start-1-1 {
    gap: 10px;
    padding: 15px;
  }
  .section-container-start-1 {
    width: 45%;
    padding: 10px;
  }
}
/* tab  */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .section-container-start {
    width: 90%;
    gap: 20px;
  }
  .section-container-start-1-1 {
    gap: 10px;
    padding: 15px;
  }
  .section-container-start-1-image svg {
    width: 45px;
  }
  .section-container-start {
    justify-content: center;
  }
  .section-container-start-1 {
    width: 28%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .section-container-start-1-desc > span {
    font-size: 15px;
  }
  .swiper-slide h2 {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .button-36 {
    padding: 0 2.6rem;
  }
}
