@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "newus-bold";
  src: url(font/static/Inter-Bold.ttf);
}
@font-face {
  font-family: "newus-extrabold";
  src: url(font/static/Inter-ExtraBold.ttf);
}
@font-face {
  font-family: "newus-Regular";
  src: url(font/static/Inter-Regular.ttf);
}
@font-face {
  font-family: "newus-SemibBold";
  src: url(font/static/Inter-SemiBold.ttf);
}
@font-face {
  font-family: "newus-black";
  src: url(font/static/Inter-Black.ttf);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.regular {
  font-family: "newus-Regular";
}
.extrabold {
  font-family: "newus-extrabold";
}
.bold {
  font-family: "newus-bold";
}
.black {
  font-family: "newus-black";
}
.html {
  color: #f75421;
}
.css {
  color: #006cb4;
}
.javascript {
  color: #f7d800;
}
.jQuery {
  color: #2962ff;
}
.react {
  color: #5ed3f4;
}
.Angular {
  color: #d61a15;
}
.php {
  color: #7377ad;
}
.nodejs {
  color: #7fc728;
}
.mysql {
  color: #5e7580;
}
.mongodb {
  color: #4da53f;
}
.wordpress {
  color: #007298;
}
.laravel {
  color: #f72c1f;
}
.django {
  color: #092d1e;
}
.paint {
  color: rgb(78, 125, 156);
}
.vfx {
  color: rgb(0, 255, 255);
}
.texturing {
  color: rgb(255, 153, 0);
}
.audio-mixing {
  color: rgb(0, 255, 170);
}
.graphic-dig {
  color: rgb(0, 255, 0);
}
.graphic-off {
  color: rgb(0, 47, 255);
}
.nav {
  font-family: "newus-Regular";
}
.spinner {
  border: #370556 solid 5px;
}
.banner h2 {
  color: #fff;
  font-family: "newus-bold";
}
.liner {
  background: linear-gradient(145.07deg, #683fbe -2.83%, #493080 89%);
  /* box-shadow: 0 .625rem 1.25rem .625rem rgba(104, 63, 190, .1); */
}
.extra{
  background: linear-gradient(145.07deg, #683fbe -2.83%, #493080 89%);
}
.section-container-start-1-desc span {
  font-family: "newus-SemibBold";
}
.services h2 {
  font-family: "newus-bold";
}
.cards {
  background-color: #fbf6f6;
}
.cards h1 {
  font-family: "newus-bold";
  color: #670e8c;
}
.totalstudents h2,
.placed h2 {
  font-size: 96px;
  padding: 0px;
  font-family: "newus-black";
  color: #fff;
}
.totalstudents h3 {
  color: #fff;
  font-size: 96px;
  line-height: 80px;
  font-family: "newus-Regular";
}
.placed:nth-child(1) h3 {
  font-size: 59px;
  line-height: 55px;
  place-content: center;
  font-family: "newus-Regular";
  color: #fff;
}
.placed:nth-child(2) h3 {
  font-family: "newus-Regular";
  color: #fff;
  font-size: 3em;
  place-content: center;
}
/* review */
.test h2 {
  font-family: "newus-extrabold";
  text-align: center;
  font-size: 39px;
}
.swiperh3 {
  font-size: 25px;
  font-family: "newus-bold";
}
.swiper-slide h2 {
  font-family: "newus-bold";
  font-size: 27px;
}
.swiper-slide p {
  font-family: "newus-Regular";
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
}
.swiper-slide button {
  font-family: "newus-extrabold";
  font-size: 15px;
}
.subcontainer-1-head > span {
  font-family: "newus-extrabold";
  color: #fff;
}
.subcontainer-1-start-details-1 > span {
  font-family: "newus-Regular";
}
.subcontainer-1-start-details-2 > span {
  font-family: "newus-Regular";
  color: #fff;
}
.subcontainer-1-end-details-1 > p {
  font-family: "newus-Regular";
}
.button-submit {
  font-family: "newus-extrabold";
}
.title {
  font-family: "newus-black";
}
.job,
.time {
  font-family: "newus-Regular";
  font-weight: 700;
}
.out {
  font-family: "newus-Regular";
}
.about-head {
  font-family: "newus-extrabold";
}
.courseshead {
  width: 100%;
  background-color: #39035b;
}
.contentcourse {
  color: #370556;
  font-family: "newus-extrabold";
}
.full > h3 {
  font-family: "newus-extrabold";
}
.errorh1 > h1 {
  font-family: "newus-extrabold";
}
.course-head {
  color: #370556;
  font-family: "newus-extrabold";
}
.text-color {
  color: #b409b7;
}
.tools-covered-container {
  /* background: #28282B; */
  position: relative;
  overflow: hidden;
  color: white;
  z-index: 2;
}
.tools-covered-container .content-container {
  max-width: 90rem;
  margin: 0 auto;
  /* padding: 4.5rem calc(min(7.5rem, 8.34%)); */
  padding-left: 4.5rem;
  padding-right: 4.5rem;
  margin-top: 20px;
}
.tools-covered {
  background: linear-gradient(
    45deg,
    rgb(0, 0, 0) 0%,
    rgb(62, 12, 113) 50%,
    rgb(146, 9, 148) 100%
  );
  border-radius: 0.75rem;
  padding: 4.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  overflow: hidden;
}
.tools-covered-container .tools-covered .heading {
  font-size: 2.5rem;
  line-height: 130%;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #fff;
}
.tools-covered-container .tools-covered .description {
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: 3rem;
  text-align: center;
  width: 52.5%;
}
.tools-covered-container .tools-covered .dot-wave-left {
  position: absolute;
  left: -12.5rem;
  bottom: -18rem;
  height: auto;
  width: 53rem;
  z-index: -1;
}
.tools-covered-container .tools-covered .dot-wave-right {
  position: absolute;
  right: -19.5rem;
  bottom: -9.5rem;
  height: auto;
  width: 38.75rem;
  z-index: -1;
  transform: scaleX(-1);
}
.curriculum-highlight-banner-container,
.inner-banner {
  /* background: #171421; */
  background: linear-gradient(
    45deg,
    rgb(0, 0, 0) 0%,
    rgb(62, 12, 113) 50%,
    rgb(146, 9, 148) 100%
  );
  overflow: hidden;
  color: var(--white);
}
.curriculum-highlight-banner-container .content-container {
  max-width: 90rem;
  margin: 0 auto;
  padding: 4.5rem calc(min(2.5rem, 8.34%));
}
.curriculum-highlight-banner-container .content-container .banner {
  width: 100%;
  min-height: 21.75rem;
  display: flex;
  border-radius: 1rem;
  /* background: #171421; */
  /* border: 1px solid rgba(140, 214, 241, .3); */
  z-index: 2;
}
.curriculum-highlight-banner-container
  .content-container
  .banner
  .left-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  width: 64%;
  flex-shrink: 0;
  margin: 5rem 0 4rem 3.5rem;
}
.curriculum-highlight-banner-container
  .content-container
  .banner
  .left-section
  .header {
  width: 60%;
  color: #fff;
  font-family: "Plus Jakarta Sans";
  font-size: 2.5rem;
  font-weight: 700;
}
.curriculum-highlight-banner-container
  .content-container
  .banner
  .left-section
  .description {
  color: #fff;
  font-size: 1rem;
  line-height: 140%;
  width: 88%;
}
.curriculum-highlight-banner-container
  .content-container
  .banner
  .right-section {
  display: flex;
  align-items: center;
}
.curriculum-highlight-banner-container
  .content-container
  .banner
  .right-section
  img {
  width: 100%;
  height: auto;
}
.count {
  background: linear-gradient(
    45deg,
    rgb(0, 0, 0) 0%,
    rgb(62, 12, 113) 50%,
    rgb(146, 9, 148) 100%
  );
}

@media only screen and (min-width: 768px) {
  .parent:hover .child {
    opacity: 1;
    height: auto;
    overflow: none;
    transform: translateY(0);
  }
  .child {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transform: translateY(-10%);
    z-index: 9999;
  }
}
.jobfind{
  background: linear-gradient(to bottom, #D5DEE7 0%, #E8EBF2 50%, #E2E7ED 100%), linear-gradient(to bottom, rgba(0,0,0,0.02) 50%, rgba(255,255,255,0.02) 61%, rgba(0,0,0,0.02) 73%), linear-gradient(33deg, rgba(255,255,255,0.20) 0%, rgba(0,0,0,0.20) 100%);
  background-blend-mode: normal,color-burn;
}